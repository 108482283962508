<template>
  <div class="document">
    <div class="item">
      <p class="avue-tip-title">大屏轮播</p>
      <el-input placeholder="请输入大屏ID多个大屏用','间隔" v-model="value1">
        <span slot="append" @click="goMenu2">预览大屏</span>
      </el-input>
    </div>
    <div class="item">
      <p class="avue-tip-title">HTML页面</p>
      <el-input placeholder="请输入大屏ID" v-model="value">
        <span slot="append" @click="goMenu1">预览大屏</span>
      </el-input>
    </div>
    <div class="item">
      <p class="avue-tip-title">产品注册</p>
      <el-input placeholder="请输入序列号" v-model="sn">
        <span slot="append" @click="register">注册</span>
      </el-input>
      <avue-data-icons :option="option"></avue-data-icons>
    </div>
    <wechat ref="wechat"></wechat>
  </div>
</template>

<script>
import { getList, getObj, addObj, delObj, updateObj, register, getRegister } from '@/api/sysconfig'
export default {
  data() {
    return {
      value: '1',
      value1: '1,2,5,6',
      sn: '',
      option: {}
    }
  },

  created() {
    this.getRegisterInfo();
  },
  methods: {
    register() {
      if (!this.sn) {
        this.$message.error('请输入序列号!');
        return
      }
      register({ data: this.sn }).then(() => {
        this.$message.success('注册成功');
        this.getRegisterInfo();
      })
    },
    getRegisterInfo() {
      getRegister().then(res => {
        this.sn = res.data?.data?.sn?.data;
        this.option = {
          span: 4,
          data: [
            {
              count: this.sn,
              title: '序列号',
              icon: 'el-icon-tickets',
            },
            {
              count: (res.data?.data?.registerInfo?.PERIOD>0) ? '已激活' : '未激活',
              title: '激活状态',
              icon: 'el-icon-loading',
            },
            {
              count: res.data?.data?.registerInfo?.CUS_NAME,
              title: '客户名称',
              icon: 'el-icon-s-custom',
            },
            {
              count: res.data?.data?.registerInfo?.NETNUM,
              title: '并发用户数',
              icon: 'el-icon-user',
            },
            {
              count: res.data?.data?.registerInfo?.RALNUM,
              title: '最大可用看板数',
              icon: 'el-icon-data-board',
            },
            {
              count: res.data?.data?.registerInfo?.PERIOD,
              title: '还剩激活天数',
              icon: 'el-icon-alarm-clock',
            }
          ]
        }
      })
    },
    goMenu1() {
      window.open('/view.html?id=' + this.value)
    },
    goMenu2() {
      window.open('/swiper.html?id=' + this.value1)
    },
    goApi() {
      window.open('https://api.data.bladex.cn/doc.html')
    },
    goDoc() {
      window.open('https://www.kancloud.cn/smallwei/avue-doc')
    },
    goDoc1() {
      window.open('https://kdocs.cn/l/cuOoWqq98ljO')
    },
    goVip() {
      window.open('https://avuejs.com/views/vip.html')
    }
  }
}
</script>

<style lang="scss">
.document {
  padding: 0 30px;

  .item {
    margin-bottom: 20px;
  }

  .avue-tip-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
</style>