import { url } from '@/config';
import request from '../axios'
export const getList = (params) => request({
  url: url + '/sysconfig/list',
  method: 'get',
  params: params
});



export const getObj = (id) => request({
  url: url + '/sysconfig/detail',
  method: 'get',
  params: {
    id
  }
});

export const addObj = (data) => request({
  url: url + '/sysconfig/save',
  method: 'post',
  data: data
});
export const updateObj = (data) => request({
  url: url + '/sysconfig/update',
  method: 'post',
  data: data
});



export const delObj = (id) => request({
  url: url + '/sysconfig/remove',
  method: 'post',
  params: {
    ids: id
  }
});


export const register = (data) => request({
  url: url + '/sysconfig/register',
  method: 'post',
  data: data
});

export const getRegister = (data) => request({
  url: url + '/sysconfig/register',
  method: 'get',
  data: data
});